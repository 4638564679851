import React, { useState, useContext, useEffect } from "react";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery
} from '@material-ui/core';
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import { useHistory } from "react-router-dom";
import RegularInput from "../components/RegularInput";
import Alert from "@material-ui/lab/Alert";
import { userExists } from "../services/AvisService";

const Email = () => {

    const history = useHistory();
    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const [open, setOpen] = useState(false);
    const avisPercent = process.env.REACT_APP_AVIS_PERCENT;

    const [formData, setFormData] = useState({
        email: null
    });

    const temporaryEmails = [
        /* "yopmail",
         "biyac"*/
    ];

    useEffect(() => {

    })


    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const setFieldValue = (field) => (value) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const setFieldChecked = (field) => (event) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.checked,
        }));
    };

    const checkEmailValidity = (email) => {
        var emailList = email.split("@");
        var emailDomain = emailList[emailList.length - 1];
        if (temporaryEmails.some(temporaryEmail => emailDomain.indexOf(temporaryEmail) > -1)) {
            return false;
        } else {
            return true
        }
    }

    const handleSubmit = async (event) => {
        setOpen(false);
        event.preventDefault();
        if (checkEmailValidity(formData.email)) {
            setUser((prevState) => ({
                ...prevState,
                email: formData.email,
            }));

            var int = randomIntFromInterval(0, 10000);
            var shouldGoOnAvis = int < avisPercent * 100;

            try {
                // Si il y a le moindre problème on redirige l'utilisateur vers la page de paiement
                userExists(formData.email).then(res => {
                    var result = res.data.userExists;
                    if (shouldGoOnAvis && result == false) {
                        history.push("/accueil/avis");
                    } else {
                        history.push("/accueil/paiement");
                    }
                })
            } catch (e) {
                history.push("/accueil/paiement");
            }
        } else {
            setOpen(true);
        }
    };

    const randomIntFromInterval = (min, max) => { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }


    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <div>
            <Grid container direction={desktop ? 'row' : 'column'}>
                <Grid item xs={12} md={9}>
                    <Typography variant="h1" className="nowrap">Votre <span className="color2">KBIS</span> officiel <span className="display-desktop">de moins de 3 mois</span> en 1 clic</Typography>
                    <Typography variant="h2">Obtenez votre document certifié et mis à jour.<br ></br> Sans délai en téléchargement. </Typography>
                    <div className="white-container" >
                        <div className="progress">
                            <div className="progress-state progress-state-3"></div>
                        </div>

                        <div className="white-container-content white-container-search green-container-content">
                            <Grid container direction="column" alignItems={desktop ? "flex-start" : "center"}  >
                                <Grid item><div className="box-step">Étape 3/3</div></Grid>
                                <Grid item><div className="box-title">{societe.hasKbis ? "Kbis" : "Justificatif officiel"} Société {societe.name} trouvé !</div></Grid>
                                <Grid item><div className="search-subtitle">Votre {societe.hasKbis ? "Kbis" : "justificatif officiel"} est prêt à être téléchargé !</div></Grid>
                            </Grid>
                            <Grid container direction="column" alignItems={desktop ? "flex-start" : "center"} >

                                <div className="label-email">Entrez votre adresse mail de réception</div>
                                <form className="main-form-email" onSubmit={handleSubmit}>

                                    <Collapse in={open}>
                                        <Alert
                                            style={{ marginBottom: "15px" }}
                                            severity="error"
                                            action={
                                                <i
                                                    className="fas fa-times"
                                                    style={{ color: "inherit", cursor: "pointer" }}
                                                    onClick={() => setOpen(false)}
                                                ></i>
                                            }
                                        >
                                            Veuillez utiliser une adresse email valide.
                                        </Alert>
                                    </Collapse>
                                    <RegularInput
                                        autoFocus
                                        placeholder="E-mail..."
                                        value={formData.email}
                                        onChange={setField("email")}
                                        required
                                        type="email"
                                    ></RegularInput>
                                    <Button type="submit" variant="contained" color="primary" className="cta cta-close">
                                        <Grid container alignItems="center" justify="space-between" >
                                            <Grid item></Grid>
                                            <Grid item>Obtenir mon KBIS</Grid>
                                            <img src="/img/arrow-right2.svg" ></img>
                                        </Grid>
                                    </Button>
                                </form>
                                <div className="email-informations">
                                    Portail des sociétés est une plateforme vous permettant de télécharger votre KBIS en quelques clics et de centraliser vos documents en ligne.
                                </div>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} className="right-side-image-container">
                    <img className="right-side-image right-side-image-email" src="/img/email-doc.svg"></img>
                </Grid>
            </Grid>
            <Grid container direction="column" alignItems="center" justify="center" className="display-mobile" style={{ marginTop: "22px" }}  >
                <Grid item><div className="info-logos">Service indépendant, données générées & accessibles depuis:</div></Grid>
                <Grid container direction="row" alignItems="center" justify="center"  >
                    <Grid item><img src="/img/partner11.png"></img></Grid>
                    <Grid item><img src="/img/partner2.svg" style={{ marginLeft: "17px", marginRight: "19px" }}></img></Grid>
                    <Grid item><img src="/img/partner3.svg" style={{ marginRight: "13px" }}></img></Grid>
                    <Grid item><img src="/img/partner4.svg"></img></Grid>
                </Grid>
            </Grid>

            <Grid container direction="row" className="email-icons-container display-desktop">
                <Grid item xs={12} md={8}>
                    <Grid container direction="row">
                        <Grid item xs={4}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item><img className="email-icon" src="/img/icon-grey1.svg"></img></Grid>
                                <Grid item className="email-icon-description">Paiement sécurisé</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item><img className="email-icon" src="/img/icon-grey2.svg"></img></Grid>
                                <Grid item className="email-icon-description">Document officiel</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item><img className="email-icon" src="/img/icon-grey3.svg"></img></Grid>
                                <Grid item className="email-icon-description">Protection des données</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} className="display-desktop">

                </Grid>
            </Grid>
        </div >
    )
}
export default Email;